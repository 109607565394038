import React, { useEffect, useState } from 'react';
import { ColorPicker } from 'primereact/colorpicker';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

interface OCOlorPickerProps {
    className?: string | undefined;
    style?: React.CSSProperties | undefined;
    color?: string | undefined;
    onChangeColor: (color: string) => void;
    onHide: () => void;
    visible: boolean;
}

export const OColorPicker: React.FC<OCOlorPickerProps> = (props) => {
    const [color, setColor] = useState<any>()
    const {t} = useTranslation();

    useEffect(() => {
        setColor(props.color);
    }, [props.color]);


    return (
        <Dialog visible={props.visible} onHide={() => {
            props.onHide()
        }} dismissableMask={true} closable={false} closeOnEscape={true}
                content={({hide}) => (
                    <div className="flex flex-column p-4" style={{borderRadius: '12px', backgroundColor: 'white'}}>
                        <ColorPicker className="flex justify-content-around" value={color?.replaceAll("#", "")} onChange={(e) => setColor("#" + e.value)} inline/>
                        <InputText className="mt-1 mb-3"
                                   style={{border: 'solid 1px #e3e3e3', borderRadius: '4px', height: '40px', width: '193px'}}
                                   value={color}
                                   onChange={(e) => setColor(e.target.value)}
                        />
                        <div>
                            <Button label={t('generic.cancel')} icon="pi pi-times" onClick={() => props.onHide()} className="p-button-text"/>
                            <Button label={t('generic.ok')} icon="pi pi-check" onClick={() => props.onChangeColor(color as string)} autoFocus/>
                        </div>

                    </div>
                )}>
        </Dialog>
    );
};

