import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoCard } from '../molecules';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Pencil } from '../atoms';

export const PleaseCreateADidFirstView: FC = (props) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            <InfoCard className="mb-4" icon={<Pencil height="30" width="30"/>}
                      title={t('screens.pleaseCreateADidFirstView.title')}
                      description={t('screens.pleaseCreateADidFirstView.description')}/>
            <div className="mt-6 ml-2 mr-2">
                <Button label={t('screens.pleaseCreateADidFirstView.createLink')} onClick={() => {
                    navigate('/did/new')
                }}></Button>
            </div>
        </>
    )
}

