import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import { useTranslation } from 'react-i18next';


export interface CredentialAttributeActionMenuProps {
    className?: string | undefined;
    onAdd?: () => void;
    onDelete?: () => void;
    onUpEnabled?: boolean;
    onUp?: () => void;
    onDownEnabled?: boolean;
    onDown?: () => void;
}

export const CredentialAttributeActionMenu: React.FC<CredentialAttributeActionMenuProps> = (props) => {
    const {t} = useTranslation();

    return (
        <div className={"flex " + props?.className}>
            <Tooltip target=".pi"/>
            <span className="pi pi-caret-up"
                  data-pr-tooltip="Move attribute up"
                  data-pr-position="left"
                  style={{color: '#777777', visibility: (props.onUpEnabled === true && props.onUp !== undefined) ? 'visible' : 'hidden'}}
                  onClick={props.onUp}></span>
            <span className="pi pi-caret-down"
                  data-pr-tooltip="Move attribute down"
                  data-pr-position="left"
                  style={{color: '#777777', visibility: (props.onDownEnabled === true && props.onDown !== undefined) ? 'visible' : 'hidden'}}
                  onClick={props.onDown}></span>
            <span className="pi pi-plus"
                  style={{color: '#777777', visibility: (props.onAdd !== undefined) ? 'visible' : 'hidden'}}
                  data-pr-tooltip="Add attribute"
                  data-pr-position="left"
                  onClick={props.onAdd}></span>
            <span className="pi pi-times"
                  data-pr-tooltip="Delete attribute"
                  data-pr-position="left"
                  style={{color: '#777777', visibility: (props.onDelete !== undefined) ? 'visible' : 'hidden'}}
                  onClick={props.onDelete}></span>
        </div>
    );
};

