import React, { CSSProperties } from 'react';
import { CredentialIssuerCredentialAttribute, CredentialIssuerCredentialAttributeType } from '../../../state';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'primereact/checkbox';
import { CredentialAttributeTypeSelector } from './CredentialAttributeTypeSelector';
import { produce } from 'immer';
import { CredentialAttributeActionMenu } from './CredentialAttributeActionMenu';
import { FormTextInput } from '../FormTextInput';
import { FieldValues, UseFormReturn } from 'react-hook-form';

interface CredentialAttributeInputProps {
    className?: string | undefined;
    style?: CSSProperties | undefined;
    credentialIssuerCredentialAttribute: CredentialIssuerCredentialAttribute;
    onChangeCredentialIssuerCredentialAttribute: (credentialIssuerCredentialAttribute: CredentialIssuerCredentialAttribute) => void;
    onDeleteCredentialIssuerCredentialAttribute: (credentialIssuerCredentialAttribute: CredentialIssuerCredentialAttribute) => void;
    onMoveOrderCredentialIssuerCredentialAttribute: (credentialIssuerCredentialAttribute: CredentialIssuerCredentialAttribute, number: number) => void;
    isFirstAttribute: boolean;
    isLastAttribute: boolean;
    formHook: UseFormReturn<FieldValues, any, FieldValues | undefined>;
}

export const CredentialAttributeInput: React.FC<CredentialAttributeInputProps> = (props) => {
    const {t} = useTranslation();

    function onChange(attribute: string, value: string | boolean | undefined) {
        const newValue = Object.assign({}, props.credentialIssuerCredentialAttribute, {[attribute]: (typeof value == 'string') ? value.trimStart() : value})
        props.onChangeCredentialIssuerCredentialAttribute(newValue);
    }

    function onChangeAttributeType(value: string | boolean | undefined) {
        const newValue = Object.assign({}, props.credentialIssuerCredentialAttribute, {attributeType: value})

        if (value === CredentialIssuerCredentialAttributeType.OBJECT) {
            let newState = produce(newValue, draft => {
                if (draft.children === undefined) {
                    draft.children = [];
                }
                if (draft.children.length === 0) {
                    draft.children.push({attributeType: CredentialIssuerCredentialAttributeType.STRING, attributeOrder: 0});
                }
            });
            props.onChangeCredentialIssuerCredentialAttribute(newState);
        } else {
            let newState = produce(newValue, draft => {
                draft.children = undefined;
            });
            props.onChangeCredentialIssuerCredentialAttribute(newState);
        }
    }


    function handleChildUpdate(index: number, credentialIssuerCredentialAttribute: CredentialIssuerCredentialAttribute) {
        let newState = produce(props.credentialIssuerCredentialAttribute, draft => {
            if (draft.children !== undefined) {
                draft.children[index] = credentialIssuerCredentialAttribute
            }
        });
        props.onChangeCredentialIssuerCredentialAttribute(newState);
    }

    function handleChildDelete(index: number) {
        let newState = produce(props.credentialIssuerCredentialAttribute, draft => {
            draft.children?.splice(index, 1);
        });
        props.onChangeCredentialIssuerCredentialAttribute(newState);
    }


    function addAttribute() {
        let newState = produce(props.credentialIssuerCredentialAttribute!, draft => {
            if (draft.children === undefined) {
                draft.children = [];
            }
            draft.children.push({attributeType: CredentialIssuerCredentialAttributeType.STRING, attributeOrder: 0});
        });

        props.onChangeCredentialIssuerCredentialAttribute(newState);
    }

    function swapChildAttributeOrder(position1: number, position2: number) {
        if (props.credentialIssuerCredentialAttribute.children === undefined) {
            return
        }

        if ((Math.min(position1, position2) >= 0) && (Math.max(position1, position2) < props.credentialIssuerCredentialAttribute.children.length)) {
            let newState = produce(props.credentialIssuerCredentialAttribute, draft => {
                const item1AttributeOrder = draft.children![position1].attributeOrder;
                const item2AttributeOrder = draft.children![position2].attributeOrder;
                draft.children![position1].attributeOrder = item2AttributeOrder;
                draft.children![position2].attributeOrder = item1AttributeOrder;
            });
            props.onChangeCredentialIssuerCredentialAttribute(newState);
        }
    }

    return (
        <div className={"flex flex-column " + props.className} style={Object.assign({border: 'none', borderRadius: 16, backgroundColor: '#ffffff'}, props.style)}>
            <div className="flex justify-content-between grid">
                <div className="col-12 md:col-7 p-0 pl-2 pb-1">
                    <div className="text-xs pb-1 pl-2" style={{color: 'rgba(28, 28, 28, 0.40)'}}>Attribute name</div>
                    <div className="flex">
                        <FormTextInput className="w-full" value={props.credentialIssuerCredentialAttribute.attributeName} style={{border: 'none'}}
                                    onChangeValue={(value) => onChange('attributeName', value)}
                                    placeholder={t('screens.credentialAttributeInput.placeHolder')}
                                    formHook={props.formHook}
                                    rules={{
                                        required: {value: true, message: t('error.inputErrors.requiredField')},
                                        pattern: {value: /^\S*$/i, message: t('error.inputErrors.noSpacesAllowed')}
                                    }}
                        />
                    </div>
                </div>
                <div className="col-4 md:col-3 p-0">
                    <div className="text-xs pb-1 pl-2" style={{color: 'rgba(28, 28, 28, 0.40)'}}>Attribute type</div>
                    <div className="flex">
                        <CredentialAttributeTypeSelector className="" value={props.credentialIssuerCredentialAttribute.attributeType}
                                                         onSelect={(value) => onChangeAttributeType(value)}/>
                    </div>
                </div>
                <div className="col-2 md:col-1 p-0 flex flex-row align-items-start justify-content-end">
                    <div className="flex flex-column align-items-end">
                        <div className="text-xs pb-2 pl-0" style={{color: 'rgba(28, 28, 28, 0.40)'}}>Is array</div>
                        <Checkbox className="" checked={props.credentialIssuerCredentialAttribute.isArray === undefined ? false : props.credentialIssuerCredentialAttribute.isArray!} style={{border: 'none'}}
                                  onChange={(e) => onChange('isArray', e.target.checked)}
                                  type="checkbox"
                        />
                    </div>
                </div>
                <div className="col-2 md:col-1 p-0 flex flex-row align-items-start justify-content-end">
                    <div className="flex flex-column align-items-end">
                        <div className="text-xs pb-2 pl-0" style={{color: 'rgba(28, 28, 28, 0.40)'}}>Required</div>
                        <Checkbox className="" checked={props.credentialIssuerCredentialAttribute.mandatory === undefined ? false : props.credentialIssuerCredentialAttribute.mandatory!} style={{border: 'none'}}
                                  onChange={(e) => onChange('mandatory', e.target.checked)}
                                  type="checkbox"
                        />
                    </div>
                    <div>
                        <CredentialAttributeActionMenu
                            className="flex-column ml-2"
                            onUpEnabled={!props.isFirstAttribute}
                            onDownEnabled={!props.isLastAttribute}
                            onUp={() => props.onMoveOrderCredentialIssuerCredentialAttribute(props.credentialIssuerCredentialAttribute, -1)}
                            onDown={() => props.onMoveOrderCredentialIssuerCredentialAttribute(props.credentialIssuerCredentialAttribute, 1)}
                            onDelete={() => props.onDeleteCredentialIssuerCredentialAttribute(props.credentialIssuerCredentialAttribute)}/>
                    </div>
                </div>
            </div>
            {(props.credentialIssuerCredentialAttribute.attributeType === CredentialIssuerCredentialAttributeType.OBJECT) && (
                <div>
                    <div className="flex align-items-stretch">
                        <div className="col-2 p-0"></div>
                        <div className="col-10 p-0" style={{borderLeft: 'solid 1px', borderBottom: 'solid 1px', borderLeftColor: '#d5d5d5', borderBottomColor: '#d5d5d5'}}>
                            {props.credentialIssuerCredentialAttribute.children?.map((attribute, index) => (
                                <CredentialAttributeInput className="mb-3"
                                                          key={index}
                                                          credentialIssuerCredentialAttribute={attribute}
                                                          onChangeCredentialIssuerCredentialAttribute={credentialIssuerCredentialAttribute => handleChildUpdate(index, credentialIssuerCredentialAttribute)}
                                                          onDeleteCredentialIssuerCredentialAttribute={credentialIssuerCredentialAttribute => handleChildDelete(index)}
                                                          onMoveOrderCredentialIssuerCredentialAttribute={(credentialIssuerCredentialAttribute, number) => swapChildAttributeOrder(index, index + number)}
                                                          isFirstAttribute={index === 0}
                                                          isLastAttribute={index === props.credentialIssuerCredentialAttribute.children!.length - 1}
                                                          formHook={props.formHook}
                                />

                            ))
                            }
                            <div className="flex justify-content-end mb-2">
                                <CredentialAttributeActionMenu
                                    onAdd={() => {
                                        addAttribute()
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            )}
        </div>
    )
        ;
};

