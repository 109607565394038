import * as React from 'react';
import { FC, useEffect, useRef } from 'react';

import { Button } from 'primereact/button';
import { CredentialIssuerCredentialAttribute, CredentialIssuerCredentialAttributeType, useAppDispatch } from '../../state';
import { OCard } from '../molecules';
import { CredentialAttributeInput } from '../molecules/credentialattributes/CredentialAttributeInput';
import { produce } from 'immer';
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload';
import keycloak from '../../keycloak';
import { importJsonSchema } from '../../state/slices/credentialissuercredentialdefinitionform/CredentialIssuerCredentialDefinitionFormApi';
import { useSelector } from 'react-redux';
import { credentialIssuerCredentialDefinitionFormSelector } from '../../state/slices/credentialissuercredentialdefinitionform';
import { Toast } from 'primereact/toast';
import { FieldValues, UseFormReturn } from 'react-hook-form';

export interface IssuerCredentialAttributesFormProps {
    credentialIssuerCredentialAttributes?: CredentialIssuerCredentialAttribute[];
    onCredentialIssuerCredentialAttributes: (credentialIssuerCredentialAttributes: CredentialIssuerCredentialAttribute[]) => void;
    formHook: UseFormReturn<FieldValues, any, FieldValues | undefined>;
}

export const IssuerCredentialAttributesForm: FC<IssuerCredentialAttributesFormProps> = (props) => {
    const fileUploadRef = useRef<FileUpload>(null);
    const toast = useRef<Toast>(null);
    let dispatch = useAppDispatch();

    let credentialIssuerCredentialDefinitionForm = useSelector(credentialIssuerCredentialDefinitionFormSelector);
    useEffect(() => {
        if (credentialIssuerCredentialDefinitionForm.error) {
            toast.current?.show({ severity: 'error', summary: credentialIssuerCredentialDefinitionForm.error});
        }
    }, [credentialIssuerCredentialDefinitionForm.error]);

    function handleFormUpdate(index: number, credentialIssuerCredentialAttribute: CredentialIssuerCredentialAttribute) {
        let newState = produce(props.credentialIssuerCredentialAttributes!, draft => {
            draft[index] = credentialIssuerCredentialAttribute
        });
        props.onCredentialIssuerCredentialAttributes(newState);
    }

    function handleFormDelete(index: number) {
        let newState = produce(props.credentialIssuerCredentialAttributes!, draft => {
            draft.splice(index, 1);
        });
        props.onCredentialIssuerCredentialAttributes(newState);

    }

    function addAttribute() {
        const maxAttributeOrder = Math.max(...props.credentialIssuerCredentialAttributes!.map(o => o.attributeOrder), 0);
        let newState = produce(props.credentialIssuerCredentialAttributes!, draft => {
            draft.push({attributeType: CredentialIssuerCredentialAttributeType.STRING, attributeOrder: maxAttributeOrder + 1});
        });

        props.onCredentialIssuerCredentialAttributes(newState);
    }

    function swapAttributeOrder(position1: number, position2: number) {
        if (props.credentialIssuerCredentialAttributes === undefined) {
            return
        }
        if ((Math.min(position1, position2) >= 0) && (Math.max(position1, position2) < props.credentialIssuerCredentialAttributes?.length)) {
            let newState = produce(props.credentialIssuerCredentialAttributes, draft => {
                const item1AttributeOrder = draft[position1].attributeOrder;
                const item2AttributeOrder = draft[position2].attributeOrder;
                draft[position1].attributeOrder = item2AttributeOrder;
                draft[position2].attributeOrder = item1AttributeOrder;
            });
            props.onCredentialIssuerCredentialAttributes(newState);
        }
    }

    const uploadJsonSchema = async (event: FileUploadHandlerEvent) => {
        // convert file to base64 encoded
        const file = event.files[0];
        const reader = new FileReader();
        let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url

        reader.readAsDataURL(blob);

        reader.onloadend = function () {
            const base64data = reader.result;
            if (base64data !== undefined) {
                const index = base64data!.toString().indexOf(";base64,")
                const b64 = base64data!.toString().substring(index + 8);
                dispatch(importJsonSchema({jwtToken: keycloak.token!, base64EncodedJsonSchema: b64}))
            }
        };
        if (fileUploadRef.current) {
            fileUploadRef.current.clear();
        }
    };

    console.log(props.formHook.formState.errors);

    return (
        <>
            <Toast ref={toast} />
            <OCard className="mt-4">
                <div className="flex pb-4 align-items-center justify-content-between">
                    <div className="font-semibold">Credential attributes</div>
                    <FileUpload ref={fileUploadRef} mode="basic" name="demo[]" accept="application/json" maxFileSize={1000000} customUpload uploadHandler={uploadJsonSchema} auto chooseLabel="Import from json schema"
                                pt={{
                                    basicButton: {
                                        className: 'p-button-secondary',
                                        style: {border: '1px solid #636363FF'}
                                    },
                                }}
                    />
                </div>

                {(props.credentialIssuerCredentialAttributes && props.credentialIssuerCredentialAttributes.length > 0) && (
                    <div>
                        {props.credentialIssuerCredentialAttributes.map((attribute, index) => (
                            <CredentialAttributeInput className="mb-3"
                                                      style={{paddingLeft: 20, paddingRight: 20, paddingTop: 18, paddingBottom: 14}}
                                                      key={index}
                                                      credentialIssuerCredentialAttribute={attribute}
                                                      onChangeCredentialIssuerCredentialAttribute={credentialIssuerCredentialAttribute => handleFormUpdate(index, credentialIssuerCredentialAttribute)}
                                                      onDeleteCredentialIssuerCredentialAttribute={credentialIssuerCredentialAttribute => handleFormDelete(index)}
                                                      onMoveOrderCredentialIssuerCredentialAttribute={(credentialIssuerCredentialAttribute, number) => swapAttributeOrder(index, index + number)}
                                                      isFirstAttribute={index === 0}
                                                      isLastAttribute={index === props.credentialIssuerCredentialAttributes!.length - 1}
                                                      formHook={props.formHook}
                            />
                        ))
                        }
                    </div>
                )}
                <Button className="p-2 m-2" onClick={addAttribute}>Add attribute</Button>
            </OCard>
        </>
    )
        ;
}
