import React, { useState } from 'react';
import { OColorPicker } from './OColorPicker';

export interface ColorInputWithLabelProps {
    label: string;
    value?: string | undefined;
    onChangeValue: (value: string | undefined) => void;
    placeHolder?: string | undefined;
    className?: string | undefined;
}

export const ColorInputWithLabel: React.FC<ColorInputWithLabelProps> = (props) => {
    const [dialogVisible, setDialogVisible] = useState<boolean>(false);

    return (
        <div className={props.className} style={{border: 'none', borderRadius: 16, paddingLeft: 20, paddingRight: 20, paddingTop: 18, paddingBottom: 14, backgroundColor: '#ffffff'}}>
            <div className="text-xs pb-1 pl-2" style={{color: 'rgba(28, 28, 28, 0.40)'}}>{props.label}</div>
            <div className="flex pl-2">
                <div style={{backgroundColor: props.value, border: 'solid black 1px', borderRadius: '4px', width: '40px', height: '20px'}} onClick={event => {
                    setDialogVisible(!dialogVisible)
                }}></div>
                <OColorPicker
                    visible={dialogVisible}
                    color={props.value}
                    onChangeColor={color => {
                        props.onChangeValue(color);
                        setDialogVisible(false);
                    }}
                    onHide={() => {
                        setDialogVisible(false);
                    }}
                />
            </div>
        </div>
    );
};

