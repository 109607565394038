import React, { FC, useMemo } from 'react';
import { CredentialIssuerCredentialDefinition, CredentialIssuerCredentialDisplay, CredentialIssuerDefinition } from '../../state';
import { useTranslation } from 'react-i18next';
import { OCard } from '../molecules';
import { FlagIcon } from '../atoms';
import { TabPanel, TabView } from 'primereact/tabview';
import { CredentialIssuerCredentialDisplayInput } from './CredentialIssuerCredentialDisplayInput';
import { FieldValues, UseFormReturn } from 'react-hook-form';

export interface CredentialIssuerCredentialDisplaysInputProps {
    credentialIssuerDefinition: CredentialIssuerDefinition;
    credentialIssuerCredentialDefinition: CredentialIssuerCredentialDefinition;
    credentialIssuerCredentialDisplays: CredentialIssuerCredentialDisplay[];
    onChangeCredentialIssuerCredentialDisplay: (credentialIssuerCredentialDefinition: CredentialIssuerCredentialDefinition, credentialIssuerCredentialDisplay: CredentialIssuerCredentialDisplay) => void;
    formHook: UseFormReturn<FieldValues, any, FieldValues | undefined>;
}

export const CredentialIssuerCredentialDisplaysInput: FC<CredentialIssuerCredentialDisplaysInputProps> = (props) => {
    const {t} = useTranslation();

    const defaultDisplay = useMemo(() => {
        return props.credentialIssuerCredentialDisplays?.find((display) => display.locale === undefined);
    }, [props.credentialIssuerCredentialDisplays]);
    const otherDisplays = useMemo(() => {
        return props.credentialIssuerCredentialDisplays?.filter((display) => display.locale !== undefined);
    }, [props.credentialIssuerCredentialDisplays]);

    function getHeader(locale?: string) {
        const label = (locale === undefined) ? t('screens.credentialIssuerDisplay.defaultLanguageLabel') : locale;
        const flag = (locale === undefined) ? <div/> : <FlagIcon height="20" locale={locale} className="mr-2"/>;
        return <div className="flex align-items-center" style={{height: 26}}>{flag}{label}</div>;
    }


    return (
        <OCard className="mt-4" title={t('screens.credentialIssuerDisplay.title')}>
            <div className="mb-4">{t('screens.credentialIssuerDisplay.description')}</div>
            <TabView panelContainerStyle={{backgroundColor: 'unset'}} className="credentialTabView" style={{paddingLeft: -16}} renderActiveOnly={false}>
                <TabPanel header={getHeader()}>
                    <CredentialIssuerCredentialDisplayInput key="default"
                                                            locale={defaultDisplay?.locale}
                                                            credentialIssuerDefinition={props.credentialIssuerDefinition}
                                                            credentialIssuerCredentialDefinition={props.credentialIssuerCredentialDefinition}
                                                            credentialIssuerCredentialDisplays={props.credentialIssuerCredentialDisplays}
                                                            onChangeCredentialIssuerCredentialDisplay={(display) => props.onChangeCredentialIssuerCredentialDisplay(props.credentialIssuerCredentialDefinition, display)}
                                                            formHook={props.formHook}
                    />

                </TabPanel>
                {otherDisplays?.map((display, index) => (
                    <TabPanel header={getHeader(display.locale)} key={index}>
                        <CredentialIssuerCredentialDisplayInput key={display.id}
                                                                locale={display?.locale}
                                                                credentialIssuerDefinition={props.credentialIssuerDefinition}
                                                                credentialIssuerCredentialDefinition={props.credentialIssuerCredentialDefinition}
                                                                credentialIssuerCredentialDisplays={props.credentialIssuerCredentialDisplays}
                                                                onChangeCredentialIssuerCredentialDisplay={(display) => props.onChangeCredentialIssuerCredentialDisplay(props.credentialIssuerCredentialDefinition, display)}
                                                                formHook={props.formHook}
                        />

                    </TabPanel>
                ))
                }
            </TabView>
        </OCard>
    )
}

