import * as React from 'react';
import { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CredentialIssuerCredentialDefinition, CredentialIssuerCredentialDisplay, credentialIssuerDefinitionSelector, getCredentialIssuerDefinition, useAppDispatch } from '../../../state';
import { InfoCard, OCard, OFabContainer } from '../../molecules';
import { useTranslation } from 'react-i18next';
import { Receive } from '../../atoms/icons/Receive';

import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { TabPanel, TabView } from 'primereact/tabview';
import { LanguageInput } from '../../molecules/LanguageInput';
import { CredentialIssuerCredentialDisplaysInput, PleaseCreateADidFirstView } from '../../organisms';
import { CredentialIssuerTemplateForm } from '../../organisms/CredentialIssuerTemplateForm';
import { IssuerTrustForm } from '../../organisms/IssuerTrustForm';
import { IssuerCredentialStatusForm } from '../../organisms/IssuerCredentialStatusForm';
import { getCredentialIssuerCredentialDefinition, saveCredentialIssuerCredentialDefinition } from '../../../state/slices/credentialissuercredentialdefinition';
import {
    credentialIssuerCredentialDefinitionFormSelector,
    setCredentialIssuerCredentialDefinitionFormToEmptyState,
    setInitialCredentialIssuerCredentialDefinitionForm,
    setSelectedCredentialIssuerCredentialDisplayLanguages,
    updateCredentialIssuerCredentialDefinition,
    updateCredentialIssuerCredentialDisplay
} from '../../../state/slices/credentialissuercredentialdefinitionform';
import { IssuerCredentialIssueForm } from '../../organisms/IssuerCredentialIssueForm';
import { IssuerCredentialAttributesForm } from '../../organisms/IssuerCredentialAttributesForm';
import { useForm } from 'react-hook-form';
import { FormTextInputWithLabel } from '../../molecules/FormTextInputWithLabel';
import { Did, didSelector, getDids } from '../../../state/slices/did';


export const CredentialIssuerCredentialDefinitionForm: FC = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {credentialIssuerDefinitionId} = useParams()
    const {credentialIssuerCredentialDefinitionId} = useParams()
    const {keycloak} = useKeycloak();
    let navigation = useNavigate();

    let credentialIssuerCredentialDefinitionForm = useSelector(credentialIssuerCredentialDefinitionFormSelector);
    const formHook = useForm();
    let credentialIssuerDefinition = useSelector(credentialIssuerDefinitionSelector).singleItem;


    useEffect(() => {
        dispatch(getDids({jwtToken: keycloak.token!}));
    }, [keycloak.token]);
    let dids = useSelector(didSelector);

    const hasDids = useMemo(() => {
        if ((dids !== undefined) && dids.loadExecuted) {
            return dids.list.length > 0;
        }
        return true;
    }, [dids]);

    useEffect(() => {
        if (credentialIssuerDefinitionId !== undefined) {
            dispatch(getCredentialIssuerDefinition({jwtToken: keycloak.token!, credentialIssuerDefinitionId: credentialIssuerDefinitionId}))
        }
        if ((credentialIssuerDefinitionId !== undefined) && (credentialIssuerCredentialDefinitionId !== undefined)) {
            dispatch(getCredentialIssuerCredentialDefinition({jwtToken: keycloak.token!, credentialIssuerDefinitionId: credentialIssuerDefinitionId, credentialIssuerCredentialDefinitionId})).then((response) => {
                if (response.type.includes('fulfilled')) {
                    dispatch(setInitialCredentialIssuerCredentialDefinitionForm(response.payload));
                }
            });
        } else {
            dispatch(setCredentialIssuerCredentialDefinitionFormToEmptyState({}));
        }
    }, [credentialIssuerDefinitionId, credentialIssuerCredentialDefinitionId]);

    function handleFormUpdate(attributeName: string, value: string | string[] | Did | undefined) {
        // console.log('QQQ handleFormUpdate: ', attributeName, value);
        dispatch(updateCredentialIssuerCredentialDefinition(Object.assign({}, credentialIssuerCredentialDefinitionForm.form, {[attributeName]: (typeof value == 'string') ? value.trimStart() : value})));
    }

    function onCancel() {
        navigation('/credentialIssuerDefinition');
    }

    function onSaveConfiguration() {
        if (credentialIssuerDefinitionId === undefined) {
            return;
        }
        formHook.trigger();
        if (formHook.formState.isValid) {
            dispatch(saveCredentialIssuerCredentialDefinition({
                jwtToken: keycloak.token!,
                credentialIssuerDefinitionId,
                credentialIssuerCredentialDefinitionId,
                credentialIssuerCredentialDefinition: credentialIssuerCredentialDefinitionForm.form!
            })).then((response) => {
                if (response.type.includes('fulfilled')) {
                    setTimeout(() => { // Use timeout the give time to update the redux store.
                        navigation('/credentialIssuerDefinition');
                    }, 250);
                }
            });
        }
    }

    function handleLanguagesUpdate(languages: string[]) {
        dispatch(setSelectedCredentialIssuerCredentialDisplayLanguages(languages));
    }

    const selectedLanguages = useMemo(() => {
        if (credentialIssuerCredentialDefinitionForm.form?.displays === undefined) {
            return [];
        }
        return credentialIssuerCredentialDefinitionForm.form?.displays.map((display) => display.locale);
    }, [credentialIssuerCredentialDefinitionForm.form?.displays]) as string[];

    function handleDisplayUpdate(credentialIssuerCredentialDefinition: CredentialIssuerCredentialDefinition, updatedDisplay: CredentialIssuerCredentialDisplay) {
        dispatch(updateCredentialIssuerCredentialDisplay(updatedDisplay));
    }

    if ((credentialIssuerCredentialDefinitionForm?.form === undefined) || (credentialIssuerDefinition == null)) {
        return null;
    }

    if (!hasDids) {
        return (
            <PleaseCreateADidFirstView/>
        )
    }
    return (
        <div className="pb-8 mb-8">
            {(credentialIssuerDefinitionId === undefined) && (
                <InfoCard className="mt-4" icon={<Receive height="30" width="30"/>}
                          title={t('screens.credentialIssuerCredentialDefinitionForm.intro.new.title')}
                          description={t('screens.credentialIssuerCredentialDefinitionForm.intro.new.description')}/>
            )}
            {(credentialIssuerDefinitionId !== undefined) && (
                <InfoCard className="mt-4" icon={<Receive height="30" width="30"/>}
                          title={t('screens.credentialIssuerCredentialDefinitionForm.intro.edit.title')}
                          description={t('screens.credentialIssuerCredentialDefinitionForm.intro.edit.description')}/>
            )}

            <OCard className="mt-4" title={t('screens.credentialIssuerCredentialDefinitionForm.saveConfigurationTitle')}>
                <FormTextInputWithLabel className="mb-3"
                                        label='Name'
                                        placeHolder='Name'
                                        value={credentialIssuerCredentialDefinitionForm?.form?.credentialConfigurationId}
                                        onChangeValue={(value) => handleFormUpdate('credentialConfigurationId', value)}
                                        formHook={formHook}
                                        rules={{required: {value: true, message: t('error.inputErrors.requiredField')}}}

                />

                <div style={{border: 'none', borderRadius: 16, paddingLeft: 20, paddingRight: 20, paddingTop: 18, paddingBottom: 14, backgroundColor: '#ffffff'}}>
                    <div className="text-xs pb-1 pl-2" style={{color: 'rgba(28, 28, 28, 0.40)'}}>{t('screens.credentialIssuerCredentialDefinitionForm.languageTitle')}</div>
                    <LanguageInput className="mb-2 w-full" placeHolder={t('screens.credentialIssuerCredentialDefinitionForm.languagePlaceHolder')} selectedLocales={selectedLanguages}
                                   onLocalesSelected={languages => handleLanguagesUpdate(languages)}/>
                </div>
            </OCard>

            <TabView onBeforeTabChange={(e) => {
                formHook.trigger();
                return formHook.formState.isValid;
            }}>
                <TabPanel header="Display">
                    <CredentialIssuerCredentialDisplaysInput credentialIssuerDefinition={credentialIssuerDefinition}
                                                             credentialIssuerCredentialDefinition={credentialIssuerCredentialDefinitionForm?.form}
                                                             credentialIssuerCredentialDisplays={credentialIssuerCredentialDefinitionForm?.form.displays!}
                                                             onChangeCredentialIssuerCredentialDisplay={(credentialIssuerCredentialDefinition, credentialIssuerCredentialDisplay) => handleDisplayUpdate(credentialIssuerCredentialDefinition, credentialIssuerCredentialDisplay)}
                                                             formHook={formHook}
                    />
                </TabPanel>
                <TabPanel header="Attibutes">
                    <IssuerCredentialAttributesForm credentialIssuerCredentialAttributes={credentialIssuerCredentialDefinitionForm?.form.attributes}
                                                    onCredentialIssuerCredentialAttributes={(credentialIssuerCredentialAttributes) => dispatch(updateCredentialIssuerCredentialDefinition({attributes: credentialIssuerCredentialAttributes}))}
                                                    formHook={formHook}
                    />
                </TabPanel>
                <TabPanel header="Template">
                    <CredentialIssuerTemplateForm credentialIssuerCredential={credentialIssuerCredentialDefinitionForm?.form}
                                                  onChangeIssuerDid={(did) => handleFormUpdate('issuerDid', did)}
                                                  onChangeTemplate={(template: string) => handleFormUpdate('template', template)}
                    />
                </TabPanel>
                <TabPanel header="Trust anchor" disabled={credentialIssuerCredentialDefinitionForm?.form?.issuerDid?.type !== 'ebsi'}>
                    <IssuerTrustForm credentialIssuerCredential={credentialIssuerCredentialDefinitionForm?.form}/>
                </TabPanel>
                <TabPanel header="Status">
                    <IssuerCredentialStatusForm credentialIssuerCredential={credentialIssuerCredentialDefinitionForm?.form}/>
                </TabPanel>
                <TabPanel header="Issue">
                    <IssuerCredentialIssueForm credentialIssuerCredential={credentialIssuerCredentialDefinitionForm?.form}/>
                    <IssuerCredentialStatusForm credentialIssuerCredential={credentialIssuerCredentialDefinitionForm?.form}/>
                </TabPanel>
            </TabView>
            <OFabContainer className="w-full">
                <Button label={t('generic.cancel')} severity="secondary" size="small" className="w-max" onClick={onCancel}/>
                <Button label="Save configuration" size="small" className="w-max" onClick={onSaveConfiguration}/>
                {/*disabled={!(isValidForm && credentialIssuerCredentialDefinitionForm.dirty)}*/}
            </OFabContainer>
        </div>
    );
}
