import * as React from 'react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { CredentialIssuerCredentialDefinition, issueCredentialDefinition, useAppDispatch } from '../../state';
import { OCard, TextInputWithLabel } from '../molecules';
import { CredentialStatusList } from '../../state/slices/credentialstatus';
import QRCode from 'react-qr-code';

export interface IssuerCredentialIssueFormProps {
    credentialIssuerCredential?: CredentialIssuerCredentialDefinition;
}

export const IssuerCredentialIssueForm: FC<IssuerCredentialIssueFormProps> = (props) => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {keycloak, initialized} = useKeycloak();
    let navigation = useNavigate();
    const [selectedStatusList, setSelectedStatusList ] = useState<CredentialStatusList>();
    const [credentialForm, setCredentialForm ] = useState<any>({});
    const [oidcIssueUrl, setOidcIssueUrl] = useState<string>();

    function handleFormUpdate(attributeName: string, value: string | undefined) {
        setCredentialForm(Object.assign({}, credentialForm, {[attributeName]: (typeof value == 'string') ? value.trimStart() : value}));
    }

    function handleIssue() {
        dispatch(issueCredentialDefinition({jwtToken: keycloak.token!, credentialIssuerDefinitionId: props.credentialIssuerCredential?.credentialConfigurationId!, credentialForm: credentialForm})).then((response) => {
            if (response.type.includes('fulfilled')) {
                setOidcIssueUrl(response.payload)
            }
        });
    }

    return (
        <>
            <div className="grid">
                <div className="col">
                    <OCard className="mt-4" title={t('screens.credentialIssuerDefinitionForm.issue.title')}>
                        {(props.credentialIssuerCredential && props.credentialIssuerCredential.attributes && props.credentialIssuerCredential.attributes.length > 0) && (
                                <div>
                                    {props.credentialIssuerCredential.attributes.map((attribute, index) => (
                                        <TextInputWithLabel className="mb-3"
                                                            key={index}
                                                            label={attribute.attributeName!}
                                                            placeHolder={t('screens.credentialIssuerDefinitionForm.issue.placeHolder')}
                                                            value={credentialForm[attribute.attributeName!]}
                                                            onChangeValue={(value) => handleFormUpdate(attribute.attributeName!, value)}/>
                                    ))
                                    }
                                </div>
                        )}
                        <Button className="p-2 m-2" onClick={handleIssue} >Issue</Button>
                    </OCard>
                </div>
                <div className="col">
                    <OCard className="mt-4 col" title={t('screens.credentialIssuerDefinitionForm.qrcode.title')}>
                        <div style={{height: "auto", margin: "0 auto", maxWidth: 200, width: "100%"}}>
                            <QRCode
                                size={256}
                                style={{height: "auto", maxWidth: "100%", width: "100%"}}
                                value={oidcIssueUrl ? oidcIssueUrl : 'oidc://'}
                                viewBox={`0 0 256 256`}
                            />
                        </div>
                    </OCard>
                </div>
            </div>
        </>
)
;
}
