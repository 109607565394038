import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';

import { bearerAuth } from '../auth';
import axios from 'axios';
import { addQueryParam } from '../slice';
import { CredentialIssuerCredentialDefinition } from '../model';


export const getCredentialIssuerCredentialDefinitions = createAsyncThunk(
    'credentialIssuerCredentialDefinition/getCredentialIssuerCredentialDefinitions', ({jwtToken, page, pageSize, credentialIssuerDefinitionExternalKey}: {
        jwtToken: string | undefined,
        page: number,
        pageSize: number,
        credentialIssuerDefinitionExternalKey?: string
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };


        var url = '/credentialIssuerDefinition/' + credentialIssuerDefinitionExternalKey + '/credentialDefinition';
        url = addQueryParam(url, 'page', page);
        url = addQueryParam(url, 'size', pageSize);

        return axios.get(url, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const getCredentialIssuerCredentialDefinition = createAsyncThunk(
    'credentialIssuerCredentialDefinition/getCredentialIssuerCredentialDefinition', ({jwtToken, credentialIssuerDefinitionId, credentialIssuerCredentialDefinitionId}: {
        jwtToken: string | undefined,
        credentialIssuerDefinitionId: string,
        credentialIssuerCredentialDefinitionId: string
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        var url = '/credentialIssuerDefinition/' + credentialIssuerDefinitionId + '/credentialDefinition/' + credentialIssuerCredentialDefinitionId;
        return axios.get(url, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

export const saveCredentialIssuerCredentialDefinition = createAsyncThunk(
    'credentialIssuerCredentialDefinition/saveCredentialIssuerCredentialDefinition', ({jwtToken, credentialIssuerDefinitionId, credentialIssuerCredentialDefinitionId, credentialIssuerCredentialDefinition}: {
        jwtToken: string | undefined,
        credentialIssuerDefinitionId: string,
        credentialIssuerCredentialDefinitionId: string | undefined,
        credentialIssuerCredentialDefinition: CredentialIssuerCredentialDefinition
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };

        var url = '/credentialIssuerDefinition/' + credentialIssuerDefinitionId + '/credentialDefinition';
        if (credentialIssuerCredentialDefinitionId !== undefined) {
            url = url + '/' + credentialIssuerCredentialDefinitionId;
        }

        return axios.post(url, credentialIssuerCredentialDefinition, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

