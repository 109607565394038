import { createSlice } from '@reduxjs/toolkit';
import { CredentialIssuerCredentialDefinition, defaultGenericPagableState, GenericPageableState } from '../model';
import { addGenericPageableStateListBuilders, addGenericStateSingleBuilders } from '../slice';
import { getCredentialIssuerCredentialDefinition, getCredentialIssuerCredentialDefinitions } from './CredentialIssuerCredentialDefinitionApi';


export interface CredentialIssuerCredentialDefinitionState extends GenericPageableState<CredentialIssuerCredentialDefinition> {
}

export const credentialIssuerCredentialDefinitionSlice = createSlice({
    name: 'credentialIssuerCredentialDefinition',
    initialState: defaultGenericPagableState,
    reducers: {},
    extraReducers: builder => {
        addGenericPageableStateListBuilders(builder, getCredentialIssuerCredentialDefinitions);
        addGenericStateSingleBuilders(builder, getCredentialIssuerCredentialDefinition);
    }
});

