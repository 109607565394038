import React from 'react';

import { Dropdown } from 'primereact/dropdown';
import { CredentialIssuerCredentialAttributeType } from '../../../state';
import { useTranslation } from 'react-i18next';


export interface CredentialAttributeTypeSelectorProps {
    className?: string | undefined;
    value?: CredentialIssuerCredentialAttributeType | undefined;
    onSelect: (type: CredentialIssuerCredentialAttributeType) => void;
}

export const CredentialAttributeTypeSelector: React.FC<CredentialAttributeTypeSelectorProps> = (props) => {
    const {t} = useTranslation();

    function itemTemplate(option: CredentialIssuerCredentialAttributeType) {
        return (
            <div>{t('fields.credentialIssuerCredentialAttributeType.' + option)}</div>
        );
    }

    return (
        <Dropdown value={props.value} onChange={(e) => props.onSelect(e.value)}
                  options={Object.keys(CredentialIssuerCredentialAttributeType)}
                  optionLabel="displayName"
                  placeholder="Select a type"
                  itemTemplate={itemTemplate}
                  valueTemplate={itemTemplate}
                  checkmark={true}
                  highlightOnSelect={false}/>

    );
};

