import { Did } from '../did';
import { CredentialStatusList } from '../credentialstatus';

export enum CredentialIssuerCredentialAttributeType {
    'OBJECT' = 'OBJECT',
    'STRING' = 'STRING',
    'INTEGER' = 'INTEGER',
    'NUMBER' = 'NUMBER',
    'BOOLEAN' = 'BOOLEAN'
}

export interface CredentialIssuerCredentialType {
    id?: string;
    credentialType?: string;
    typeOrder?: number;
}

export interface CredentialIssuerCredentialDisplay {
    id?: string;
    displayName?: string;
    backgroundColor?: string;
    textColor?: string;
    backgroundImageUri?: string;
    locale?: string;
}

export interface CredentialIssuerCredentialAttributeDisplay {
    id?: string;
    displayName?: string;
    locale?: string;
}

export interface CredentialIssuerCredentialAttribute {
    id?: string;
    displays?: CredentialIssuerCredentialAttributeDisplay[]
    attributeName?: string;
    attributeOrder: number;
    mandatory?: boolean;
    isArray?: boolean;
    attributeType?: CredentialIssuerCredentialAttributeType
    children?: CredentialIssuerCredentialAttribute[];
}

export interface CredentialIssuerCredentialDefinition {
    id?: string;
    credentialConfigurationId?: string;
    credentialIssuerDefinitionExternalKey?: string;
    format?: string;
    displays?: CredentialIssuerCredentialDisplay[];
    types?: CredentialIssuerCredentialType[];
    attributes?: CredentialIssuerCredentialAttribute[];
    issuerDid?: Did;
    statusList?: CredentialStatusList;
    template?: string;
}

export interface CredentialIssuerDisplay {
    id?: string;
    displayName?: string;
    logoUri?: string;
    logoAltText?: string;
    locale?: string;
}

export interface CredentialIssuerDefinition {
    id?: string;
    externalKey?: string;
    name?: string;
    description?: string;
    openIdCredentialIssuerUrl?: string;
    displays?: CredentialIssuerDisplay[];
    credentialDefinitions?: CredentialIssuerCredentialDefinition[];
    createdAt?: string;
    createdBy?: string;
    lastModifiedAt?: string;
    lastModifiedBy?: string;
}
