import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';

import { bearerAuth } from '../auth';
import axios from 'axios';


export const importJsonSchema = createAsyncThunk(
    'credentialIssuerCredentialDefinitionForm/importJsonSchema', ({jwtToken, base64EncodedJsonSchema}: {
        jwtToken: string | undefined,
        base64EncodedJsonSchema: string,
    }, thunkAPI) => {
        thunkAPI.dispatch(setLoading(true));
        const config = {
            headers: {'Authorization': bearerAuth(jwtToken)}
        };


        var url = '/credentialIssuerDefinitionAttributes/jsonSchema';
        const body = {
            base64EncodedJsonSchema: base64EncodedJsonSchema
        }
        return axios.post(url, body, config)
            .then(response => {
                return response.data
            })
            .finally(() => {
                thunkAPI.dispatch(setLoading(false));
            });
    },
);

