import React, { FC, useMemo } from 'react';
import { buildCredentialType, CredentialIssuerCredentialDefinition, CredentialIssuerCredentialDisplay, CredentialIssuerDefinition } from '../../state';
import { useTranslation } from 'react-i18next';
import { ColorInputWithLabel, CredentialTypeCard, FormTextInputWithLabel } from '../molecules';
import { FieldValues, UseFormReturn } from 'react-hook-form';

export interface CredentialIssuerCredentialDisplayInputProps {
    locale: string | undefined;
    credentialIssuerDefinition: CredentialIssuerDefinition;
    credentialIssuerCredentialDefinition: CredentialIssuerCredentialDefinition;
    credentialIssuerCredentialDisplays: CredentialIssuerCredentialDisplay[];
    onChangeCredentialIssuerCredentialDisplay: (credentialIssuerCredentialDisplay: CredentialIssuerCredentialDisplay) => void;
    formHook: UseFormReturn<FieldValues, any, FieldValues | undefined>;
}

export const CredentialIssuerCredentialDisplayInput: FC<CredentialIssuerCredentialDisplayInputProps> = (props) => {
    const {t} = useTranslation();

    const credentialIssuerCredentialDisplay = useMemo(() => {
        return props.credentialIssuerCredentialDisplays?.find((display) => display.locale === props.locale);
    }, [props.locale, props.credentialIssuerCredentialDisplays]);


    const fakeCredentialType = useMemo(() => buildCredentialType(props.credentialIssuerDefinition, props.credentialIssuerCredentialDisplays, props?.locale),
        [props.credentialIssuerDefinition, props.credentialIssuerCredentialDisplays, props?.locale]
    );

    function handleFormUpdate(fieldName: string, value: string | undefined) {
        props.onChangeCredentialIssuerCredentialDisplay(Object.assign({}, credentialIssuerCredentialDisplay, {[fieldName]: (value !== undefined) ? value.trimStart() : value}));
    }

    return (
        <div>
            <CredentialTypeCard credentialType={fakeCredentialType}/>
            <FormTextInputWithLabel className="mb-3"
                                    label={t('screens.credentialDisplay.attributes.name.label')}
                                    placeHolder={t('screens.credentialDisplay.attributes.name.placeHolder')}
                                    value={credentialIssuerCredentialDisplay?.displayName}
                                    onChangeValue={(value) => handleFormUpdate('displayName', value)}
                                    formHook={props.formHook}
                                    rules={{required: {value: true, message: t('error.inputErrors.requiredField')}}}
            />
            <FormTextInputWithLabel className="mb-3"
                                    label={t('screens.credentialDisplay.attributes.backgroundImageUri.label')}
                                    placeHolder={t('screens.credentialDisplay.attributes.backgroundImageUri.placeHolder')}
                                    value={credentialIssuerCredentialDisplay?.backgroundImageUri}
                                    onChangeValue={(value) => handleFormUpdate('backgroundImageUri', value)}
                                    inputType='url'
                                    formHook={props.formHook}
            />
            <ColorInputWithLabel className="mb-3"
                                 label={t('screens.credentialDisplay.attributes.backgroundColor.label')}
                                 value={credentialIssuerCredentialDisplay?.backgroundColor}
                                 onChangeValue={(value) => handleFormUpdate('backgroundColor', value)}/>
            <ColorInputWithLabel className="mb-3"
                                 label={t('screens.credentialDisplay.attributes.textColor.label')}
                                 value={credentialIssuerCredentialDisplay?.textColor}
                                 onChangeValue={(value) => handleFormUpdate('textColor', value)}/>
        </div>

    )
}

