import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CredentialIssuerCredentialAttribute, CredentialIssuerCredentialDefinition, CredentialIssuerCredentialDisplay, CredentialIssuerCredentialType, CredentialIssuerDisplay } from '../model';
import { produce } from 'immer';
import { getErrorCodeFromMessage } from '../slice';
import { importJsonSchema } from './CredentialIssuerCredentialDefinitionFormApi';
import { CredentialStatusList } from '../credentialstatus';
import { Did } from '../did';


export interface CredentialIssuerCredentialDefinitionForm {
    form: CredentialIssuerCredentialDefinition | undefined;
    dirty: boolean;
    error?: string;
}

export interface CredentialIssuerCredentialDefinitionFormState extends CredentialIssuerCredentialDefinitionForm {
}

const initialState: CredentialIssuerCredentialDefinitionForm = {
    form: {
        id: '',
        credentialConfigurationId: '',
        credentialIssuerDefinitionExternalKey: '',
        format: '',
        types: [] as CredentialIssuerCredentialType[],
        displays: [] as CredentialIssuerCredentialDisplay[],
        attributes: [] as CredentialIssuerCredentialAttribute[],
        issuerDid: {} as Did,
        statusList: {} as CredentialStatusList,
        template: ''
    } as CredentialIssuerCredentialDefinition,
    dirty: false
};


function findByLocale(source: CredentialIssuerDisplay[], locale: string): number {
    if (source) {
        return source.findIndex((element) => ((element.locale === locale)));
    }
    return -1;
}

function sortOnDisplayOrder(attributes: CredentialIssuerCredentialAttribute[] | undefined): CredentialIssuerCredentialAttribute[] | undefined {
    if (attributes === undefined) {
        return attributes;
    }

    return produce(attributes, draft => {
        draft.sort((a, b) => a.attributeOrder - b.attributeOrder);

        draft.forEach(attribute => {
            if ((attribute.children !== undefined) && attribute.children.length > 0) {
                attribute.children = sortOnDisplayOrder([...attribute.children]);
            }
        });
        draft.forEach((attribute, index) => {
            attribute.attributeOrder = index;
        })
    });
}

export const credentialIssuerCredentialDefinitionFormSlice = createSlice({
        name: 'credentialIssuerCredentialDefinitionFrom',
        initialState,
        reducers: {
            setSelectedCredentialIssuerCredentialDisplayLanguages(state: CredentialIssuerCredentialDefinitionFormState, action: PayloadAction<string[]>) {
                const newDisplays = Object.assign([], state.form?.displays);
                // Add new languages to the list of displays
                action.payload.forEach((language) => {
                    if (findByLocale(newDisplays, language) === -1) {
                        newDisplays.push({locale: language} as CredentialIssuerCredentialDisplay);
                    }
                });
                // Remove displays that are not in the new list of languages
                newDisplays.forEach((display: CredentialIssuerDisplay) => {
                    // Always keep the default language (locale = '')
                    if ((display.locale) && (display.locale?.length > 0) && action.payload.indexOf(display.locale!) === -1) {
                        const index = findByLocale(newDisplays, display.locale!);
                        if (index !== -1) {
                            newDisplays.splice(index, 1);
                        }
                    }
                });
                return {
                    ...state,
                    dirty: true,
                    form: Object.assign({}, state.form, {displays: newDisplays})
                };
            },
            updateCredentialIssuerCredentialDisplay: function (state: CredentialIssuerCredentialDefinitionFormState, action: PayloadAction<CredentialIssuerCredentialDisplay>) {
                if (action.payload === undefined) {
                    return {...state};
                }
                const oldDisplays = Object.assign([], state?.form?.displays);

                let index = findByLocale(oldDisplays, action.payload.locale!);
                const newDisplays = (index > -1)
                    ? [...oldDisplays.slice(0, index), action.payload, ...oldDisplays.slice(index + 1)]
                    : [...oldDisplays, action.payload];
                return {
                    ...state,
                    dirty: true,
                    form: Object.assign({}, state.form, {displays: newDisplays})
                };
            },

            updateCredentialIssuerCredentialDefinition: function (state: CredentialIssuerCredentialDefinitionFormState, action: PayloadAction<CredentialIssuerCredentialDefinition>) {
                return {
                    ...state,
                    dirty: true,
                    form: Object.assign({}, state.form, action.payload, {attributes: sortOnDisplayOrder(Object.assign([], action?.payload.attributes))})
                };
            },
            setInitialCredentialIssuerCredentialDefinitionForm(state: any, action) {
                return {
                    ...state,
                    dirty: false,
                    form: Object.assign({}, action.payload)
                };
            },
            setCredentialIssuerCredentialDefinitionForm(state: any, action) {
                return {
                    ...state,
                    dirty: true,
                    form: Object.assign({}, action.payload)
                };
            },
            setCredentialIssuerCredentialDefinitionFormToEmptyState(state: any, action) {
                return Object.assign({}, initialState);
            },


        },
    extraReducers: builder => {
        builder.addCase(importJsonSchema.pending.type, (state: CredentialIssuerCredentialDefinitionFormState) => {
            return {
                ...state,
                loading: true,
                error: undefined,
                errorCode: undefined
            };
        });
        builder.addCase(importJsonSchema.fulfilled.type, (state: CredentialIssuerCredentialDefinitionFormState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: undefined,
                errorCode: undefined,
                form: Object.assign({}, state.form, {attributes: action.payload}),
                dirty: true
            };
        });
        builder.addCase(importJsonSchema.rejected.type, (state: CredentialIssuerCredentialDefinitionFormState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: action.error.message,
                errorCode: getErrorCodeFromMessage(action.error.message),
            };
        });

    }
    })
;

export const {
    setInitialCredentialIssuerCredentialDefinitionForm,
    setCredentialIssuerCredentialDefinitionForm,
    setCredentialIssuerCredentialDefinitionFormToEmptyState,
    setSelectedCredentialIssuerCredentialDisplayLanguages,
    updateCredentialIssuerCredentialDisplay,
    updateCredentialIssuerCredentialDefinition
} = credentialIssuerCredentialDefinitionFormSlice.actions
