import React, { FC } from 'react';
import { CredentialIssuerDisplay } from '../../state';
import { useTranslation } from 'react-i18next';
import { TextInputWithLabel } from '../molecules';
import { Image } from 'primereact/image';

export interface CredentialIssuerDisplayInputProps {
    credentialIssuerDisplay?: CredentialIssuerDisplay;
    onChangeCredentialIssuerDisplay: (credentialIssuerDisplay: CredentialIssuerDisplay) => void;
}

export const CredentialIssuerDisplayInput: FC<CredentialIssuerDisplayInputProps> = (props) => {
    const {t} = useTranslation();

    function handleFormUpdate(fieldName: string, value: string | undefined) {
        props.onChangeCredentialIssuerDisplay(Object.assign({}, props.credentialIssuerDisplay, {[fieldName]: (value !== undefined) ? value.trimStart() : value}));
    }

    return (
        <div>
            <TextInputWithLabel className="mb-3"
                                label={t('screens.credentialIssuerDisplay.attributes.name.label')}
                                placeHolder={t('screens.credentialIssuerDisplay.attributes.name.placeHolder')}
                                value={props.credentialIssuerDisplay?.displayName}
                                onChangeValue={(value) => handleFormUpdate('displayName', value)}/>
            <TextInputWithLabel className="mb-3"
                                label={t('screens.credentialIssuerDisplay.attributes.logoUri.label')}
                                placeHolder={t('screens.credentialIssuerDisplay.attributes.logoUri.placeHolder')}
                                value={props.credentialIssuerDisplay?.logoUri}
                                onChangeValue={(value) => handleFormUpdate('logoUri', value)}
                                postElement={<Image src={props.credentialIssuerDisplay?.logoUri} alt={props.credentialIssuerDisplay?.logoAltText} width="70px" preview/>}
                                inputType='url'/>
            <TextInputWithLabel className="mb-3"
                                label={t('screens.credentialIssuerDisplay.attributes.logoAlt.label')}
                                placeHolder={t('screens.credentialIssuerDisplay.attributes.logoAlt.placeHolder')}
                                value={props.credentialIssuerDisplay?.logoAltText}
                                onChangeValue={(value) => handleFormUpdate('logoAltText', value)}/>
        </div>

    )
}

