import React, { CSSProperties, useMemo } from 'react';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';


export interface OIconTextInputProps {
    value?: string | undefined;
    onChangeValue?: (value: string | undefined) => void;
    placeHolder?: string | undefined;
    className?: string | undefined;
    inputIconClass?: string;
    onIconClicked?: (value: string | undefined) => void;
    iconPosition?: 'left' | 'right' | undefined;
    inputStyle?: CSSProperties | undefined;
}

export const OIconTextInput: React.FC<OIconTextInputProps> = (props) => {


    const iconPosition = useMemo(() => {
        return (props.iconPosition === undefined) ? 'right' : props.iconPosition;
    }, [props.iconPosition]);

    return (
        <IconField iconPosition={iconPosition}>
            <InputIcon className={props.inputIconClass} style={{paddingRight: 10}}> </InputIcon>
            <InputText className="w-full"
                       style={props.inputStyle}
                       value={props.value}
                       onChange={(e) => (props.onChangeValue !== undefined) && props.onChangeValue(e.target.value)}
                       placeholder={props.placeHolder}/>
        </IconField>
    );
};

