import React, { FC, useMemo } from 'react';
import { CredentialIssuerDisplay } from '../../state';
import { useTranslation } from 'react-i18next';
import { OCard } from '../molecules';
import { FlagIcon } from '../atoms';
import { TabPanel, TabView } from 'primereact/tabview';
import { CredentialIssuerDisplayInput } from './CredentialIssuerDisplayInput';
import './CredentialIssuerDisplaysInput.css';


export interface CredentialIssuerDisplaysInputProps {
    credentialIssuerDisplays?: CredentialIssuerDisplay[];
    onChangeCredentialIssuerDisplay: (credentialIssuerDisplay: CredentialIssuerDisplay) => void;
}

export const CredentialIssuerDisplaysInput: FC<CredentialIssuerDisplaysInputProps> = (props) => {
    const {t} = useTranslation();

    const defaultDisplay = useMemo(() => {
        return props.credentialIssuerDisplays?.find((display) => display.locale === undefined);
    }, [props.credentialIssuerDisplays]);
    const otherDisplays = useMemo(() => {
        return props.credentialIssuerDisplays?.filter((display) => display.locale !== undefined);
    }, [props.credentialIssuerDisplays]);

    function getHeader(locale?: string) {
        const label = (locale === undefined) ? t('screens.credentialIssuerDisplay.defaultLanguageLabel') : locale;
        const flag = (locale === undefined) ? <div/> : <FlagIcon height="20" locale={locale} className="mr-2"/>;
        return <div className="flex align-items-center" style={{height: 26}}>{flag}{label}</div>;
    }

    return (
        <OCard className="mt-4" title={t('screens.credentialIssuerDisplay.title')}>
            <div className="mb-4">{t('screens.credentialIssuerDisplay.description')}</div>
            <TabView panelContainerStyle={{backgroundColor: 'unset'}} className="credentialTabView" style={{paddingLeft: -16}}>
                <TabPanel header={getHeader()}>
                    <CredentialIssuerDisplayInput key="default" credentialIssuerDisplay={defaultDisplay} onChangeCredentialIssuerDisplay={(display) => {
                        props.onChangeCredentialIssuerDisplay(display);
                    }}/>
                </TabPanel>
                {otherDisplays?.map((display, index) => (
                    <TabPanel header={getHeader(display.locale)} key={index}>
                        <CredentialIssuerDisplayInput key={index} credentialIssuerDisplay={display} onChangeCredentialIssuerDisplay={(display) => {
                            props.onChangeCredentialIssuerDisplay(display);
                        }}/>
                    </TabPanel>
                ))
                }
            </TabView>
        </OCard>
    )
}

