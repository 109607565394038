import * as React from 'react';
import { FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CredentialIssuerCredentialDefinition, useAppDispatch } from '../../state';
import { Did, didSelector, getDids } from '../../state/slices/did';
import { DidSelectList, OCard, TextInputWithLabel } from '../molecules';
import { useKeycloak } from '@react-keycloak/web';

export interface IssuerTemplateFormProps {
    credentialIssuerCredential?: CredentialIssuerCredentialDefinition;
    onChangeIssuerDid: (issuerDid: Did) => void;
    onChangeTemplate: (template: string) => void;
}

export const CredentialIssuerTemplateForm: FC<IssuerTemplateFormProps> = (props) => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {keycloak} = useKeycloak();

    useEffect(() => {
        dispatch(getDids({jwtToken: keycloak.token!}));
    }, [keycloak.token]);

    let dids = useSelector(didSelector);
    const numberOfTemplateLines = useMemo(() => {
        return props.credentialIssuerCredential?.template?.split('\n').length
    }, [props.credentialIssuerCredential?.template])
    const selectedDid = useMemo(() => {
        if (dids && dids.list && dids.list.length > 0) {
            if (props.credentialIssuerCredential && props.credentialIssuerCredential.issuerDid) {
                return dids.list.filter(value => value.id === props.credentialIssuerCredential!.issuerDid!.id).at(0);
            } else {
                return dids.list.at(0);
            }
        }
    }, [dids.list, props.credentialIssuerCredential?.issuerDid]);

    return (
        <>
            <OCard className="mt-4" title={t('screens.credentialIssuerDefinitionForm.template.title')}>
                {(dids && dids.list && dids.list.length > 1) && (
                    <div className="mb-3" style={{
                        border: 'none',
                        borderRadius: 16,
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 18,
                        paddingBottom: 14,
                        backgroundColor: '#ffffff'
                    }}>
                        <div className="text-xs pb-1 pl-2"
                             style={{color: 'rgba(28, 28, 28, 0.40)'}}>{t('screens.credentialIssuerDefinitionForm.template.selectDid')} </div>
                        <DidSelectList didList={dids.list} selectedDid={selectedDid} onSelect={props.onChangeIssuerDid}/>
                    </div>
                )}
                <TextInputWithLabel className="mb-3"
                                    label='Template'
                                    placeHolder='Fill in the credential template'
                                    value={props.credentialIssuerCredential?.template}
                                    multiline={true}
                                    multilineRows={numberOfTemplateLines}
                                    onChangeValue={(value) => props.onChangeTemplate('' + value)}/>
            </OCard>
        </>
    )
        ;
}
