import { createSlice } from '@reduxjs/toolkit';
import { CredentialIssuerCredentialDisplay, CredentialIssuerDefinition, CredentialIssuerDisplay, defaultGenericPagableState, GenericPageableState } from '../model';
import { addGenericPageableStateListBuilders, addGenericStateSingleBuilders } from '../slice';
import { getCredentialIssuerDefinition, getCredentialIssuerDefinitions, issueCredentialDefinition } from './CredentialIssuerDefinitionApi';
import { CredentialType } from '../credentialtype';


export interface CredentialIssuerDefinitionState extends GenericPageableState<CredentialIssuerDefinition> {
}

export const credentialIssuerDefinitionSlice = createSlice({
    name: 'credentialIssuerDefinition',
    initialState: defaultGenericPagableState,
    reducers: {},
    extraReducers: builder => {
        addGenericPageableStateListBuilders(builder, getCredentialIssuerDefinitions);
        addGenericStateSingleBuilders(builder, getCredentialIssuerDefinition);
        addGenericStateSingleBuilders(builder, issueCredentialDefinition);
    }
});


export const buildCredentialType = (credentialIssuerDefinition: CredentialIssuerDefinition | undefined,
                                    credentialIssuerCredentialDisplays: CredentialIssuerCredentialDisplay[] | undefined,
                                    locale: string | undefined): CredentialType => {
    return {
        issuerDisplay: getIssuerDisplayWithDefault(credentialIssuerDefinition, locale),
        credentialTypeDisplay: getCredentialTypeDisplayWithDefault(credentialIssuerCredentialDisplays, locale)
    } as CredentialType
}

export const getIssuerDisplayWithDefault = (credentialIssuerDefinition: CredentialIssuerDefinition | undefined, locale: string | undefined): CredentialIssuerDisplay | undefined => {
    if (credentialIssuerDefinition === undefined) {
        return undefined;
    }
    const localeDisplay = credentialIssuerDefinition.displays?.find((display) => display.locale === locale);
    const defaultDisplay = credentialIssuerDefinition.displays?.find((display) => display.locale === undefined);
    return {
        displayName: merge(localeDisplay?.displayName, defaultDisplay?.displayName),
        logoUri: merge(localeDisplay?.logoUri, defaultDisplay?.logoUri),
        logoAltText: merge(localeDisplay?.logoAltText, defaultDisplay?.logoAltText)
    } as CredentialIssuerDisplay;
}

function getCredentialTypeDisplayWithDefault(credentialIssuerCredentialDisplay: CredentialIssuerCredentialDisplay[] | undefined, locale: string | undefined): CredentialIssuerCredentialDisplay | undefined {
    if (credentialIssuerCredentialDisplay === undefined) {
        return undefined;
    }
    const localeDisplay = credentialIssuerCredentialDisplay?.find((display) => display.locale === locale);
    const defaultDisplay = credentialIssuerCredentialDisplay?.find((display) => display.locale === undefined);
    return {
        displayName: merge(localeDisplay?.displayName, defaultDisplay?.displayName),
        backgroundColor: merge(localeDisplay?.backgroundColor, defaultDisplay?.backgroundColor),
        textColor: merge(localeDisplay?.textColor, defaultDisplay?.textColor),
        backgroundImageUri: merge(localeDisplay?.backgroundImageUri, defaultDisplay?.backgroundImageUri)
    } as CredentialIssuerCredentialDisplay;
}


export const getLocaleIssuerDisplay = (credentialIssuerDefinition: CredentialIssuerDefinition, locale: string): CredentialIssuerDisplay | undefined => {
    const localeDisplay = credentialIssuerDefinition.displays?.find((display) => display.locale === locale);
    const defaultDisplay = credentialIssuerDefinition.displays?.find((display) => display.locale === undefined);
    return {...defaultDisplay, ...localeDisplay};
}

function merge(value: string | undefined, defaultValue: string | undefined): string | undefined {
    return ((value === undefined) || (value === '')) ? defaultValue : value;
}
